
.mainDisplayContainer
{
   
    /* align-items: center;
    width: 90%;
    height: 90%;
    padding: 1%; */
    /* background-color: 'background.default';
    color: 'text.primary';
    background-image: none; */
}
.contentContainer
{

    align-items: center;
    padding: 3%;
    display: contents;
    align-self: flex;
    text-align: left;
    width: 95%;
    height: 50%;
} 


.align-right {
    display: flex !important;
    justify-content: right !important;
}
.align-left {
    display: flex !important;
    justify-content: left !important;
}

.mediaContainer
{
    padding: 2%;
    max-height: 90vh;
    max-width: 90vw;
    display: grid;
    object-fit: cover;
    clear: both;
    align-items: center;
    background-position:center;
}

.mediaContainer img
{
    object-fit: cover;
}