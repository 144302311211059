.container {
    align-items: center;
    justify-content: center;
    display: flexbox;
  }
  
  .image {
    max-width: 40vw;
    max-height: 40vh;
    float: left;
    padding-right: 10px;
  }
  
  .text {
    font-size: 18px;    
    padding-right: 10px; 
  }