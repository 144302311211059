.App {
  text-align: center;
  font: normal;
  font-size: medium;
  font-family: 'Calibri', 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
}

.read-or-hide {
  cursor: pointer;
  display: inline;
  padding: 1px 10px;
  text-decoration: underline dotted;
  font-style: italic;
}

.listItemCss {
  display: list-item;
  margin: 1px 1px 1px 2vw;
}

/* @font-face {
  font-family: "LT-TM-Barani";
   src: url('LT-TM-Barani.ttf') format('truetype'); 
  src: url('fonts/fira/eot/FiraSans-Regular.eot') format('embedded-opentype'),
       url('fonts/fira/woff2/FiraSans-Regular.woff2') format('woff2'),
       url('fonts/fira/woff/FiraSans-Regular.woff') format('woff'),
       url('fonts/fira/woff2/FiraSans-Regular.ttf') format('truetype'); 
} */

.ta {
  font-family: 'LT-TM-Barani' !important;
}

.en {
  font-family: 'Calibri', 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
}

/* @font-face {
  font-family: "Barani";
   src: url('Barani.ttf') format('truetype');
  
} */

.special-link,
.special-link:visited,
.special-link:active {
  color: inherit;
}

.special-link:hover {
  padding-right: 5px;
}

.fbIcon:hover {
  color: #1877F2;
}

.whatsAppIcon:hover {
  color: #28D146;
}

.twitterIcon:hover {
  color: #1D9BF0;
}

.liIcon:hover {
  color: #0A66C2;
}

.customAlbum {
  padding: 10px;
}

.customAlbum:hover {
  /* background-color: rgb(110, 216, 110); */
  border: darkgreen 3px dashed;
  border-radius: 10px;
  text-decoration-color: darkgreen;
  text-decoration: dashed underline;
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  width: 100%;
}

img.hover-zoom:hover {
  transform: scale(1.25);
}

.customAlbum img {
  border-radius: 20px;
}

.socialMediaIconContainer:hover {
  color: inherit;
  padding: 2px;
  width: 25px;
  height: 25px;
  background: white;
  border: #61dafb 1px solid;
  border-radius: 15px;
  transition: ease-out;
}

.galaryImage {
  border-radius: 20px !important;
}

.appBody {
  /* background-image: url('/public/page-background-image-low.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: 'background.default';
  color: 'text.primary'; */
}

.App-logo {
  height: 10vmin;
  width: 10vmin;
  pointer-events: none;
  align-self: flex-start;
  border-radius: 5px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.languageSelectorStyle {
  align-self: self-end;
  height: min-content;
  width: max-content;
  font-size: inherit;
  background-color: green;
}

.activeStyle {
  background-color: darkgreen;
  /* display: list-item; */
}


.App-header {
  /* background-color: #282c34; */
  min-height: 5vmin;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  padding-left: 2%;
  align-items: stretch;
  /*background-image: url('/public/page-background-image-low.jpg');*/
  background-size: cover;
  background-position: 'top center';
  backdrop-filter: blur(5px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a:hover {
  text-decoration: underline;
}

@media (min-width: 800px) {
  .floatRight {
    float: right;
  }
}

.applinks {
  cursor: pointer;
}

.scrollButtonStyle {
  background-color: "transparent" !important
}

.scrollButtonStyle:hover {
  background-color: "white" !important
}

.roundedContainer {
  /*background-color: white !important;*/
  padding: 10px !important;
  margin: 2px 5px !important;
  border-radius: 20px !important;
  opacity: 0.9;
}


/*Quote starts*/
blockquote {
  /* background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019"; */
  background: linear-gradient(darkgreen, lightgreen);
  margin: 1.5em 10px;
  padding: 5px 35px;
  quotes: "\201C" "\201D" "\2018" "\2019";
  background-image: url(quotebg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
  color: white;
  font-size: larger;
  font-style: italic;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 2em;
  line-height: 0.05em;
  margin-right: 0.25em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
}

blockquote:after {
  color: #ccc;
  content: close-quote;
  font-size: 2em;
  line-height: 0.05em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

/*Quote ends*/


.pageLinks a::after {
  content: "|";
  position: absolute;
  right: 0;
}

/*Image slider - starts*/
.slider {
  /* position: relative;
  height: 100vh; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin: 0px;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  display: inline-block;
  transition-duration: 2s;
  transform: scale(1.08);
}

.slide.hidden {
  opacity: 0;
  display: none;
  transition-duration: 1s;
  transform: scale(1.08);
}

.slideImage {
  width: 95%;
  height: 40vw;
  /* margin-left: 32px;
  margin-right: 32px; */
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent !important;
  /* background-size: "contain";
  background-repeat: no-repeat;
  background-position: center; */
}

.mediaControlsContainerNewLine {
  /* clear: both; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  align-content: center;
}


.mediaControlsContainerBottom {
  position: absolute;
}

.mediaIndexLinksContainer {
  display: inline-flex;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overflow:scroll;
  width: 70vw;
  height: 8vh;
}

.mediaNoIndicator
{
  font-style: italic;
  font-size: smaller;
  display: inline;
  padding-right: 5px;
}