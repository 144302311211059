body {
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./page-background-image-low.jpg'); */
  background-color: lightgreen;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;  
  padding:1px;
}

@font-face {
  font-family: "LT-TM-Barani";
  src: url('./fonts/LT-TM-Barani.woff') format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

